$loginForm = $('#login-form');
$checkLoginForm = $('#login-email-form');
$(function() {
    $('#login-form').removeClass('hide');
    $('#login-form').hide();
    $('.login-pwd').hide();
    loginFormValidation();
    loginFormListeners();
    checkLoginFormValidation();
    checkLoginFormListeners();

    $('body').on('click','.continueSignIn, .loginBtn', function (e) {
        if (!$.gdprcookie.preference()) {
            e.preventDefault();
            Swal.fire({
                icon: "error",
                text: "Please accept cookies to explore"
            }).then(function(){
                return;
            });
        }
    });

    $('body').on('click','#back-to-login', function (e) {
        e.preventDefault();
        
        $('#login-email-form').show();
        $('#login-form').hide();
        $('#signup-link').show();
        $('.login-pwd').hide();
        $('#login-email-form')[0].reset()
        $('.title').html('SIGN <span class="bottom-yellow-bg">IN</span>')
    });
});
function loginFormListeners()
{
    
    $loginForm.ajaxForm({
        url:appConfig.api_url+"signin-with-pwd",
        dataType: 'json',
        beforeSend:function(){
            showLoader();
        },
        success:function(response){
            hideLoader();
            showEmailVerificationModal($loginForm.find("[name=email]").val());
        },
        error: function (xhr) {
            hideLoader();
            var response = JSON.parse(xhr.responseText);
            // if (response.meta.message_code=='EMAIL_NOT_VERIFIED') {
                // showEmailVerificationModal($loginForm.find("[name=email]").val());
                // $loginForm[0].reset();
            // } else {
                showErrorAlertFromException(xhr)
            // }
            // }
        }
    });
}
function checkLoginFormListeners()
{
    $checkLoginForm.ajaxForm({
        url:appConfig.api_url+"check-user-existence",
        dataType: 'json',
        beforeSend:function(){
            showLoader();
        },
        success:function(response){
            console.log(response.data);
            hideLoader();
            $('#login-email-form').hide();
            $('#login-form').show();
            $('#signup-link').hide();
            $('.login-pwd').show();
            $('.title').html('WELCOME <span class="bottom-yellow-bg">'+response.data.user_type+'</span>')
            $('.email').text(response.data.email);
            $('.email').val(response.data.email);
        },
        error: function (xhr) {
            hideLoader();
            showErrorAlertFromException(xhr)
            
        }
    });
}
function ltrim(str){
    return str.replace(/^\s+/, "");
}
function checkLoginFormValidation()
{
    $checkLoginForm.validate({
        rules: {
           email: {
                required: {
                    depends:function(){
                        $(this).val(ltrim($(this).val()));
                        return true;
                    }
                },
                email:true
            },
        },
        messages: {
            email: {
                required: "Please enter your email id.",
            },
        }
    });
}
function loginFormValidation()
{
    $loginForm.validate({
        rules: {
            password: {
                required: true,
                pwcheck: true
            }
        },
        messages: {
            password: {
                required: 'Please enter your password',
            }
        }
    });
}